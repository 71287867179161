import Axios from "axios";
import { useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { importDIdata } from "../api/api.js";
import "bootstrap/dist/css/bootstrap.min.css";
import * as myLabelConst from "../components/labelComponents";
import APIConfigOverrideData from "../config/configOverrides.json";


function Dashboard() {
  const [Records, setRecords] = useState([]);
  const [importName, SetimportName] = useState("");
  const [sourceName, SetsourceName] = useState("");
  const [sourceDB, SetsourceDB] = useState("");
  const [destinationName, SetdestinationName] = useState("");
  const [destinationDB, SetdestinationDB] = useState("");
  const [empTable, SetempTable] = useState(false);
  const [openModal, SetopenModal] = useState(false);
  const [txtenable, settxtenable] = useState(false);
  const [notification, setnotification] = useState(false);

  useEffect(() => {
    const report = Axios.get(
      `${APIConfigOverrideData.DataImporterApiBaseUri}/v1/getImportDetailsData`
    )
      .then((res) =>
        setRecords(res.data.data.getImportDetailsDataResponse.data.dashboard)
      )
      .catch((err) => console.log(err));
  }, [Records]);

  const styleObj = {
    fontSize: 14,
    color: "#4a54f1",
    paddingTop: "100px",
    height: "62vh",
    width: "100%",
    fontFamily: "open_sansregular",
  };
  const btnPrimary = {
    color: "#fff",
    backgroundColor: "#3265d7",
    borderColor: "#3265d7",
    fontFamily: "Open Sans",
    fontsize: "13px",
    size: "14px/1.167rem",
    float: "right",
    width: "125px",
    padding: "5px",
  };
  const stylePopupWindow = {
    margin: "auto",
    padding: "30px",
    fontFamily: "open_sansregular",
  };

  const stylePopupWindowText = {
    width: "100%",
    marginBottom: "12px",
    marginTop: "10px",
  };
  const btnPrimaryPopup = {
    color: "#fff",
    backgroundColor: "#3265d7",
    borderColor: "#3265d7",
    fontFamily: "Open Sans",
    fontsize: "13px",
    size: "14px/1.167rem",
    float: "right",
    width: "80px",
    padding: "2px",
  };
  const onClickButton = (e) => {
    e.preventDefault();
    SetopenModal({ openModal: true });
  };
  const onCloseModal = () => {
    settxtenable(false);
    clearInputFields();
  };
  const clearInputFields = () => {
    SetopenModal(false);
    SetimportName("");
    SetsourceName("");
    SetsourceDB("");
    SetdestinationName("");
    SetdestinationDB("");
    SetempTable(false);
  };

  const handelclick = async (e) => {
    var forms = document.getElementById("validationID");
    forms.classList.add("was-validated");
    e.preventDefault();
      const DIdata = {
      ImportDescription: importName,
      SourceDBInitialCatalog: sourceName,
      SourceDBDataSource: sourceDB,
      DestinationDBInitialCatalog: destinationName,
      DestinationDBDataSource: destinationDB,
      EmployeeTableProvided: empTable,
    };

    if (
      DIdata.ImportDescription != "" &&
      DIdata.SourceDBInitialCatalog != "" &&
      DIdata.SourceDBDataSource != "" &&
      DIdata.DestinationDBInitialCatalog != "" &&
      DIdata.DestinationDBDataSource != ""
    ) {
      settxtenable(true);
      setnotification(true);
      const response = await Axios({
        method: "POST",
        mode: "cors",
        url: `${APIConfigOverrideData.DataImporterApiBaseUri}/v1/createImportDetails`,
        data: JSON.stringify(DIdata),
        config: {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Header add Access-Control-Allow-Methods ":
              "GET,POST,OPTIONS,DELETE,PUT",
            "Access-Control-Allow-Credentials": "false",
          },
        },
      })
        .then((res) => {
          SetopenModal(false);
          setnotification(false);
          clearInputFields();
          settxtenable(false);
          return res;
        })
        .catch((err) => {
          clearInputFields();
          settxtenable(false);
          setnotification(false);
          return err;
         
        });
    }
  };

  return (
    <div>
      <div>
        <button style={btnPrimary} id="btnImport" onClick={onClickButton}>
          Create Import
        </button>
      </div>
      <Modal open={openModal} onClose={onCloseModal}>
        <form id="validationID" role="form" novalidate>
          <div id="Popupwindow" style={stylePopupWindow}>
            <div>
              <label class="control-label" for="name-input">
                {myLabelConst.NameoftheImport}
                <span class="validation-required">*</span>
              </label>
            </div>
            <div>
              <input
                type="text"
                name="importName"
                class="form-control"
                aria-describedby="name-help-block"
                aria-invalid="false"
                required
                disabled={txtenable}
                style={stylePopupWindowText}
                value={importName}
                onChange={(e) => SetimportName(e.target.value)}
              />
            </div>
            <div>
              <label class="control-label" for="name-input">
                {myLabelConst.SourceServerName}
                <span class="validation-required">*</span>
              </label>
            </div>
            <div>
              <input
                type="text"
                name="sourceName"
                class="form-control"
                aria-describedby="name-help-block"
                aria-invalid="false"
                required
                disabled={txtenable}
                style={stylePopupWindowText}
                value={sourceName}
                onChange={(e) => SetsourceName(e.target.value)}
              />
            </div>
            <div>
              <label class="control-label" for="name-input">
                {myLabelConst.SourceDBName}
                <span class="validation-required">*</span>
              </label>
            </div>
            <div>
              <input
                type="text"
                name="sourceDB"
                class="form-control"
                aria-invalid="false"
                required
                disabled={txtenable}
                style={stylePopupWindowText}
                value={sourceDB}
                onChange={(e) => SetsourceDB(e.target.value)}
              />
            </div>
            <div>
              <label class="control-label" for="name-input">
                {myLabelConst.DestinationServerName}
                <span class="validation-required">*</span>
              </label>
            </div>
            <div>
              <input
                type="text"
                name="destinationName"
                aria-describedby="name-help-block"
                aria-invalid="false"
                class="form-control"
                required
                disabled={txtenable}
                style={stylePopupWindowText}
                value={destinationName}
                onChange={(e) => SetdestinationName(e.target.value)}
              />
            </div>
            <div>
              <label class="control-label" for="name-input">
                {myLabelConst.DestinationDBName}
                <span class="validation-required">*</span>
              </label>
            </div>
            <div>
              <input
                type="text"
                name="destinationDB"
                aria-describedby="name-help-block"
                aria-invalid="false"
                class="form-control"
                required
                disabled={txtenable}
                style={stylePopupWindowText}
                value={destinationDB}
                onChange={(e) => SetdestinationDB(e.target.value)}
              />
            </div>
            <div class="custom-control custom-checkbox">
              <input
                required
                type="checkbox"
                class="custom-control-input"
                name="empTable"
                disabled={txtenable}
                checked={empTable}
                onChange={(e) => SetempTable(e.target.checked)}
              />
              <span class="custom-control-label"> Employee Table Provided</span>
              <button
                style={btnPrimaryPopup}
                aria-hidden="false"
                onClick={handelclick}
                type="Submit"
                id="btnPopupImport"
                disabled={txtenable}
              >
                Import
              </button>
            </div>
          </div>
        </form>
        { notification ?  <div className="alert alert-info" role="alert">
    Processing is in-progress. Please wait ...
  </div> : null }
      </Modal>
      <div className="ag-theme-balham" style={styleObj}>
        <AgGridReact    pagination={true} paginationPageSize={10}  rowData={Records}  >
          <AgGridColumn field="importDescription"  headerName="Import Details" ></AgGridColumn>
          <AgGridColumn field="importStartDate"    headerName="Start Date" ></AgGridColumn>
          <AgGridColumn field="importStartTime"    headerName="Start Time" ></AgGridColumn>
          <AgGridColumn field="importStatus" headerName="Status"></AgGridColumn>        
          <AgGridColumn field="importEndDate"  headerName="Date Completed" ></AgGridColumn>
          <AgGridColumn field="importEndTime"  headerName="End Time" ></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  );
}
export default Dashboard;
